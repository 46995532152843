import React, { Component } from 'react';
import queryString from 'query-string';

//components
import config from '../../../config';

//assets
import  Amber from '../../../static/images/user_not_found_amber.png';
import infeedo_logo from '../../../static/images/infeedo-logo.svg';
import back_arrow from '../../../static/images/back_arrow.svg';

export default class UserNotFoundPage extends Component {
    state = {
        user_email : ''
    }
    componentDidMount () {
        if(window.location.search) {
            this.setState({user_email: (queryString.parse(window.location.search).user)})
        }
        
    }

    backToLogin = _ => {
        window.location = `${config.home_url}/login`
    }

    render() {
        const {user_email} = this.state
        return (
            <div className="not-found-background">
                <figure className="logo column has-text-left pd-bottom-15 pd-left-30 pd-top-30">
                    <img width="130px" src={infeedo_logo} alt="logo" className="mg-left-5" />
                </figure>
                <p className="column is-12 has-text-weight-bold mg-bottom-40">
                    <span onClick={this.backToLogin} className="back-button pointer pd-left-15">
                            <img src={back_arrow} alt="backbutton" /> Go Back to Login Page
                    </span>
                </p>
                <div className="columns is-desktop">
                    <div className="column no-padding-top has-text-centered mg-top-50">
                        <img className="" src={Amber} alt=""/>
                    </div>
                    <div className="column content">
                        <div className="column line-height-normal">
        <span className="is-size-2 has-text-weight-bold">Hey {user_email},</span><br/>
                            <span className="is-size-2 has-text-weight-bold">I'm </span>
                            <span className="is-size-2 has-text-weight-bold color-amber">Amber :)</span>
                        </div>
                        <div className="column is-10">
                            <span className="is-size-6">
                                I'm an AI chatbot who talks with employees to better understand their experience in their organization. But I don't seem to recognize you. Can you ask your HR to introduce us?
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
