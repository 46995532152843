import React from "react";
import UserNotFoundPage from '../components/notfoundpages/UserNotFoundPage';
import Layout from "../components/layout/layout";
import SEOComponent from "../components/seo"


const UserNotFound = () => (
    <div className="no-user">
        <Layout mode={true} no_header_margin={true}>
            <SEOComponent title="Amber by inFeedo | User Not Found" />
            <UserNotFoundPage/>
        </Layout>
    </div>
)

export default UserNotFound